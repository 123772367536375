import React from 'react'
import { Link } from 'react-router-dom'
import './PricingStyles.css'

export default function Pricing() {
  return (
    <div className='pricing'>
        <div className='card-container'>
            <div className='card'>
                <h3>- Small -</h3>
                <span className='bar'></span>
                <p className='cad'>$xx</p>
                <p>- Time Frame -</p>
                <p>- Sizes -</p>
                <p>- Colors -</p>
                <p>- Shape -</p>
                <Link to='/contact' className='btn'>Buy</Link>
            </div>
            <div className='card'>
                <h3>- Medium -</h3>
                <span className='bar'></span>
                <p className='cad'>$xx</p>
                <p>- Time Frame -</p>
                <p>- Sizes -</p>
                <p>- Colors -</p>
                <p>- Shape -</p>
                <Link to='/contact' className='btn'>Buy</Link>
            </div>
            <div className='card'>
                <h3>- Large -</h3>
                <span className='bar'></span>
                <p className='cad'>$xx</p>
                <p>- Time Frame -</p>
                <p>- Sizes -</p>
                <p>- Colors -</p>
                <p>- Shape -</p>
                <Link to='/contact' className='btn'>Buy</Link>
            </div>
        </div>
    </div>
  )
}

