import React, { Component } from 'react'
import './HeroImageStyles.css'
export class HeroImage extends Component {
  render() {
    return (
      <div className='hero-img'>
        <div className='heading'>
            <h1>{this.props.heading}</h1>
            <p>{this.props.text}</p>
            <h2 className='styled-text'>{this.props.styled_text}</h2>
        </div>

      </div>
    )
  }
}

export default HeroImage