import React from "react";
import "../components/PageNotFound.css";
import { FaHome } from "react-icons/fa";

const PageNotFound404 = () => {
  return (
    <>
      <div className="PageNotFound">
        <h1>404</h1>
        <h2>Page Not found</h2>
        <p>The URL you have entered does not exist</p>
        <a className="btn-container" href="https://www.thesilklady.ca">
          <button className="btn">Return Home <FaHome 
                size={30}
                style={{ color: "#fff" }} /></button>
        </a>
      </div>
    </>
  );
};

export default PageNotFound404;
