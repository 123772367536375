import React from 'react'
import { Link } from 'react-router-dom'
import './VideoStyles.css'
import FlowerVideo from '../assests/SilkLady.mp4'
import TypeWriter from './TypeWriter.js'

const Video = () => {
  return (
    <div className='hero'>
    <video autoPlay loop muted playsInline id='video'>
        <source src={FlowerVideo} type='video/mp4' />
    </video>
    <div className='content'>
        <h1>The Silk Lady</h1>
        <span className='specializing'>Specializing in Silk</span>
        <div><TypeWriter /></div>
        
    <div>
        <Link to ='/about' atl="Learn More About The Silk lady" className='btn'>About Us</Link>
        <Link to ='/contact' atl="Contact The Silk lady" className='btn btn-light'>Contact</Link>
    </div>
    </div>
    </div>
  )
}

export default Video