import React from "react";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import "./Gallery.css";
import { FaFacebook } from "react-icons/fa";
import PurpleSaddle from "../assests/PurpleSaddle.jpg";
import BlueSaddle from "../assests/BlueSaddle.jpg";
import OrangeSaddle from "../assests/OrangeSaddle.jpg";
import RedSaddle from "../assests/RedSaddle.jpg";
import RedGreenSaddle from "../assests/RedGreenSaddle.jpg";
import YellowCone from "../assests/YellowCone.jpg";
import PinkCone from "../assests/PinkCone.jpg";

const GalleryCarousel = () => {
  var settings = {
    className: "slickCSS",
    infinite: true,
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="galleryInfoBlock">
      <p className="galleryInfoText">
        To see current arrangements please follow the link below to The Silk
        Lady's Facebook Page!
      </p>
      <a
        className="galleryInfoLink"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/profile.php?id=100090808492372&sk=photos"
      >
        Facebook Gallery
        <FaFacebook
          className="fbGalleryLinkIcon"
          size={25}
          style={{ marginLeft: "5px" }}
        />
      </a>
      <Slider {...settings}>
        {/* <div>
        <img src={CloseUpDisplay} className="displays" alt="flower display"></img>
      </div>
      <div>
        <img src={FullDisplay} className="displays" alt="flower display"></img>
      </div>
      <div>
        <img src={UpperCloseUp} className="displays" alt="flower display"></img>
      </div> */}
        <div>
          <img
            src={BlueSaddle}
            className="saddles"
            alt="flower Saddle Saddle"
          ></img>
        </div>
        <div>
          <img
            src={OrangeSaddle}
            className="saddles"
            alt="flower Saddle"
          ></img>
        </div>
        <div>
          <img
            src={PurpleSaddle}
            className="saddles"
            alt="flower Saddle"
          ></img>
        </div>
        <div>
          <img
            src={RedSaddle}
            className="saddles"
            alt="flower Saddle"
          ></img>
        </div>
        <div>
          <img
            src={RedGreenSaddle}
            className="saddles"
            alt="flower Saddle"
          ></img>
        </div>
        <div>
          <img
            src={YellowCone}
            className="cones"
            alt="flower Cone"
          ></img>
        </div>
        <div>
          <img
            src={PinkCone}
            className="cones"
            alt="flower Cone"
          ></img>
        </div>
      </Slider>
    </div>
  );
};

export default GalleryCarousel;
