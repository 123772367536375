import React from 'react'
import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import GalleryCarousel from '../components/Gallery'
import Footer from '../components/Footer'


const Gallery = () => {
  return (
    <div>
        <Navbar />
        <HeroImage heading='Gallery' text='Below you can check out a gallery of projects and images by swiping on the images' />
        <GalleryCarousel />
        <Footer />
    </div>
  )
}

export default Gallery