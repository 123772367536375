import React from "react";
import Typewriter from "typewriter-effect";

const TypeWriter = () => {
  return (
    <>
      <Typewriter
        options={{
          autoStart: true,
          loop: true,
        }}
        onInit={(typewriter) => {
          typewriter

            .typeString("Tombstone Saddles")

            .pauseFor(1500)
            .deleteAll()
            .typeString("Tombstone Cones")

            .pauseFor(1500)
            .deleteAll()
            .typeString("Seasonal Tombstone Arrangements")

            .pauseFor(1500)
            .deleteAll()
            .typeString("& Custom Orders")
            .pauseFor(1500)
            .start();
        }}
      />
    </>
  );
};

export default TypeWriter;
