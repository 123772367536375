import React from 'react'
import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'

const Contact = () => {
  return (
    <div>
        <Navbar />
        <HeroImage heading='Get In Touch With' styled_text='The Silk Lady' />
        <ContactForm />
        <Footer />
    </div>
  )
}

export default Contact