import React from "react";
import "./FooterStyles.css";
import { FaPhone, FaMailBulk, FaSearchLocation } from "react-icons/fa";
import SocialLinks from "./SocialLinks";

const date = new Date();
let currentYear = date.getFullYear().toString();

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaSearchLocation
              size={30}
              style={{ color: "#fff", marginRight: "2rem" }}
            />
            <div>
              <p>McGee Street</p>
              <h1>Pembroke, Ont</h1>
            </div>
          </div>
          <div className="phone">
            <h1>
              <a href="tel:+16137352825">
                <FaPhone
                  size={30}
                  style={{ color: "#fff", marginRight: "2rem" }}
                />
                613-735-2825
              </a>
            </h1>
          </div>
          <div className="email">
            <h1>
              <a href="mailto:silklady@cogeco.ca">
                <FaMailBulk
                  size={30}
                  style={{ color: "#fff", marginRight: "2rem" }}
                />
                silklady@cogeco.ca
              </a>
            </h1>
          </div>
        </div>
        <div className="right">
          <h1>
            About <span>The Silk Lady</span>
          </h1>
          <p>
            Specializing in Silk Tombstone Saddles, Tombstone Cones, Seasonal
            Tombstone Arrangements &amp; Custom Orders here in the Ottawa
            Valley!
          </p>
          <SocialLinks />
        </div>
      </div>
        <div className="footer-copyright">
          <p>
            Copyright &copy; {currentYear}&nbsp; The Silk Lady
            <br/>
            Website Developed By <a className="valley-dev" alt="The Best Dev In The Ottawa Valley" href="https://www.valleydev.ca">Valley Dev</a>
          </p>
        </div>
    </div>
  );
};

export default Footer;
