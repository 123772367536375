import React from 'react'
import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import Footer from '../components/Footer'
import AboutSection from '../components/About'
const About = () => {
  return (
    <div>
        <Navbar />
        <HeroImage heading='Handmade With Love' />
        <AboutSection />
        <Footer />
    </div>
  )
}

export default About