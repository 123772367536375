import React from "react";
import "./ContactFormStyles.css";
import { FaMailBulk, FaPhone } from "react-icons/fa";
const ContactForm = () => {
  return (
    <>
      <div className="contact-container">
        <h2><u>Contact <span>The Silk Lady</span></u></h2>
        <div className="contact-info">
          <div className="email-contact">
            <h3>By Email</h3>
            <a href="mailto:silklady@cogeco.ca">
              <FaMailBulk
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
              silklady@cogeco.ca
            </a>
          </div>
          <div className="phone-contact">
            <h3>By Phone</h3>
            <a href="tel:+16137352825">
              <FaPhone
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
              613-735-2825
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
