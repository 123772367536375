import React from "react";
import "./FooterStyles.css";
import { FaFacebook } from "react-icons/fa";

function SocialLinks() {
  //   const FacebookClick = () => {
  //     window.open("https://www.facebook.com/profile.php?id=100090808492372");
  //   };

  return (
    <div className="social">
      <a
        aria-label="The Silk Lady Facebook Link"
        alt="The Silk Lady Facebook Link"
        href="https://www.facebook.com/profile.php?id=100090808492372"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook
          size={30}
          style={{ color: "#fff", marginRight: "1rem" }}
        />
      </a>
    </div>
  );
}

export default SocialLinks;
