import React from 'react'
import './AboutStyles.css'

import { Link } from 'react-router-dom'

import Workshop from '../assests/FlowerCraft.png'
import  FlowerSaddle from '../assests/FlowerSaddle.png'

const About = () => {
  return (
    <div className='about'>
    <div className='left'>
        <h1>About  <span>The Silk Lady</span></h1>
        <p>Specializing in Silk Tombstone Saddles, Tombstone Cones, Seasonal Tombstone Arrangements &amp; Custom Orders here in the Ottawa Valley!</p>
        <p>Flowers may not be able to speak but their silent presence can already tell the words too painful to say. They have a certain language that can express deep emotions of sympathy and understanding. And this is something that money could never afford to give.</p>
        <Link to='/contact'><button className='btn'>Contact</button></Link>
    </div>
    <div className='right'>
        <div className='img-container'>
            <div className='image-stack top'>
                <img src={Workshop} className='img' alt='Flower Workshop' />
            </div>
            <div className='image-stack bottom'>
                <img src={FlowerSaddle} className='img' alt='Flower Saddle' />
            </div>
        </div>
    </div>
    </div>
  )
}

export default About